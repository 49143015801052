import React from 'react';
import Container from '../../components/Container';
import ImageText from '../../components/ImageText';
import ImageTextDescription from '../../components/ImageTextDesctiption';
import Calculator from './assets/Calculator.svg';
import Federation from './assets/Federation.svg';
import Privacy from './assets/Privacy.svg';
import Tracking from './assets/Tracking.svg';

export default () => {
  return (
    <div className="content-list-wrapper tahub">
      <div>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Calculator} imgWidth={445} title="安全多方计算（Secure Multi-Party Computation / MPC）">
              <ImageTextDescription
                label="技术"
                content="秘密共享/不经意传输/混淆电路/同态加密/隐私信息检索/零知识证明等"
              />
              <ImageTextDescription
                label="说明"
                content="在加密状态下进行数据计算，彼此不知对方的数据，却能得到正确的计算结果"
              />
              <ImageTextDescription
                label="应用"
                content="利用多个数据源的不同特征，基于约定的函数规则，计算出最终的结果并应用"
              />
            </ImageText>
          </Container>
        </section>

        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Federation} imgWidth={510} imgHeight={380} title="联邦学习（Federated Machine Learning / FL） " position="right">
              <ImageTextDescription label="技术" content="横/纵向联邦学习，支持逻辑回归/随机森林/XGBoost/LightGBM/神经网络等" />
              <ImageTextDescription label="说明" content="数据不动模型动，多方共同参与模型训练和预测，多轮迭代收敛产生训练模型" />
              <ImageTextDescription label="应用" content="线索评级模型/战败归因模型/购买预测模型等" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Privacy} imgWidth={426} imgHeight={353} title="匿踪查询（Private Information Retrieval / PIR）">
              <ImageTextDescription label="技术" content="隐私信息检索，不经意传输(Oblivious Transfer - OT)/非对称加密等" />
              <ImageTextDescription label="说明" content="查询方发起查询请求，数据服务方提供匹配的查询结果却无法获知对应哪个查询对象" />
              <ImageTextDescription label="应用" content="特征增强，向三方数据源查询特定场景模型分值" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Tracking} imgWidth={510} imgHeight={380} title="隐私求交（Private Set Intersection / PSI） " position="right">
              <ImageTextDescription label="技术" content="不经意传输(Oblivious Transfer - OT)/非对称加密/可交换加密等" />
              <ImageTextDescription label="说明" content="找到双方数据中交集部分，而不暴露各自交集以外（补集）的任何数据集合信息" />
              <ImageTextDescription label="应用" content="精准圈群、特征增强、样本安全匹配" />
            </ImageText>
          </Container>
        </section>
      </div>
    </div>
  );
};
